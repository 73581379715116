















































@import '@design';

.featured-press-link {
  padding-bottom: 0.2em;
  margin-top: 0;
  text-decoration: none;
  border-bottom: 13px solid $color-bloak-piggy-pink;
  transition: border 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-bottom-color: rgba($color-bloak-piggy-pink, 0.3);
    // border-bottom-width: 7px;
  }
}
